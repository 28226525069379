import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'
import Dropdown from './Dropdown';
import APIConfig from './APIConfig';

export default class APIForm extends Component {
    constructor(props) {
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            isEdit: false,
            isCreateField: false,
            isLoading: false,
            formItems: {
                id: 0, apiType: this.props.apiType, name: '',
                api: '', username: '', password: '', provider: '',
                senderId: '', callback: '', cashoutPasscode: '',
                curuser: this.auth.getUser(), token: this.auth.getToken()
            }
        }
    }

    componentDidMount() {
        let rdata = this.props.rowData

        if ((rdata?.id || 0) > 0) {
            let formItems = this.state.formItems
            formItems = rdata
            this.setState({ formItems })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {

            let apiType = nextProps.apiType,
                rdata = nextProps.rowData,
                formItems = {
                    id: 0, apiType: this.props.apiType, name: '',
                    api: '', username: '', password: '',
                    senderId: '', callback: '', cashoutPasscode: '',
                    curuser: this.auth.getUser(), token: this.auth.getToken()
                };

            if ((rdata?.id || 0) > 0) {
                formItems = rdata;
            } else {
                formItems['apiType'] = apiType;
            }

            this.setState({ formItems });
        }
    }

    handleChange = (e) => {
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value

        this.setState({ formItems })
    }

    formSubmit = (e) => {
        e.preventDefault()

        let formItems = this.state.formItems

        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'admin/wapp/api'))
            .then((res) => {
                if (isNaN(res.data)) {
                    alert(this.auth.decryptText(res.data))
                    $('#btnACancel').click()
                    this.goBack(formItems.fieldname)
                } else {
                    this.auth.doAlert(parseInt(res.data))
                }

            }).catch((error) => { alert(error) })
    }

    goBack = () => {
        ReactDOM.render(<APIConfig />,
            document.getElementById('generalConfigCon'))
    }

    if(isLoading) {
        return <div className='loader' />
    }

    render() {
        return (
            <div className="modal fade" id="apiform" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                            <h5 className="modal-title">{(this.state.isEdit) ? 'Edit' : 'New'} API</h5>
                        </div>
                        <div className="modal-body">
                            <form id="frmDropdown" className="smart-form" onSubmit={this.formSubmit}>
                                <div className="row">
                                    {this.state.formItems.apiType == 2 &&
                                    <div className="col-xs-12 ">
                                        <label className="label col col-5 text-right">Provider: </label>
                                        <section className="col col-7">
                                            <label className="select">
                                                <select name='provider' required onChange={this.handleChange}
                                                    value={this.state.formItems.provider}>
                                                    <option value=''>-- Select Provider --</option>
                                                    <option value='NALO'>Nalo Solutions</option>
                                                    <option value='THETELLER'>The Teller</option>
                                                    <option value='HUBTEL'>Hubtel</option>
                                                    <option value='WIREPICK'>Wirepick</option>
                                                </select>
                                            </label>
                                        </section>
                                    </div>}
                                    <div className="col-xs-12 ">
                                        <label className="label col col-5 text-right">Name: </label>
                                        <section className="col col-7">
                                            <label className="input">
                                                <input type="text" name="name" required
                                                    value={this.state.formItems.name} onChange={this.handleChange} />
                                            </label>
                                        </section>
                                    </div>
                                    <div className="col-xs-12 ">
                                        <label className="label col col-5 text-right">API URL: </label>
                                        <section className="col col-7">
                                            <label className="input">
                                                <input type="text" name="api" required
                                                    value={this.state.formItems.api} onChange={this.handleChange} />
                                            </label>
                                        </section>
                                    </div>
                                    <div className="col-xs-12 ">
                                        <label className="label col col-5 text-right">Username: </label>
                                        <section className="col col-7">
                                            <label className="input">
                                                <input type="text" name="username" required
                                                    value={this.state.formItems.username} onChange={this.handleChange} />
                                            </label>
                                        </section>
                                    </div>
                                    <div className="col-xs-12 ">
                                        <label className="label col col-5 text-right">Password: </label>
                                        <section className="col col-7">
                                            <label className="input">
                                                <input type="password" name="password" required
                                                    value={this.state.formItems.password} onChange={this.handleChange} />
                                            </label>
                                        </section>
                                    </div>
                                    <div className="col-xs-12 ">
                                        <label className="label col col-5 text-right">Sender ID: </label>
                                        <section className="col col-7">
                                            <label className="input">
                                                <input type="text" name="senderId" required
                                                    value={this.state.formItems.senderId} onChange={this.handleChange} />
                                            </label>
                                        </section>
                                    </div>

                                    {this.state.formItems.apiType == 2 && <>
                                        <div className="col-xs-12 ">
                                            <label className="label col col-5 text-right">Callback URL: </label>
                                            <section className="col col-7">
                                                <label className="input">
                                                    <input type="text" name="callback" required
                                                        value={this.state.formItems.callback} onChange={this.handleChange} />
                                                </label>
                                            </section>
                                        </div>
                                        <div className="col-xs-12 ">
                                            <label className="label col col-5 text-right">Cashout Passcode: </label>
                                            <section className="col col-7">
                                                <label className="input">
                                                    <input type="text" name="cashoutPasscode" required
                                                        value={this.state.formItems.cashoutPasscode} onChange={this.handleChange} />
                                                </label>
                                            </section>
                                        </div>
                                    </>}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button id="btnACancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="submit" form="frmDropdown" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}