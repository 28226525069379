import React, { Component } from 'react';
import Auth from './Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'
import ReactDOM from 'react-dom'

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

export default class UnitRequestForm extends Component{

    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            request: this.props.unitRequest,
            commision: 0.00,
            purchase:0.00,
            loader: false,
            btnDisable: false,
            user: '',

            formItems: {
                usertype:0, totalUnit:0, commission:0, unito: '', transtype:1, 
                curuser: this.auth.getUser(), token: this.auth.getToken()
            },

            cashierBal:0.00
        }
    }

    componentDidUpdate(nextProps){

        if(this.props !== nextProps){
            let commision = 0
            let purchase = 0
            
            this.setState({ commision, purchase, btnDisable: false, request: this.props.unitRequest });
            this.getCashier(this.props.unitRequest);
        }
    }

    getCashier = (request) => {
        if(request == undefined){
            return;
        }

        this.$http.get(`admin/wapp/${this.auth.getUser()}/${this.auth.getToken()}/usertype/${request.createdby}`)
        .then(res => {
            let user = this.auth.decryptData(res.data)
            
            this.setState({ user })
        }).catch((error)=>{alert(error)})

        let unit = parseFloat(request.amount),
            commision = ((100/80) * unit) - unit,
            purchase = unit,
            formItems = {
                usertype:0, totalUnit:unit + commision, commission:commision, unito: request.createdby, transtype:1, 
                curuser: this.auth.getUser(), token: this.auth.getToken()
            };

            this.setState({formItems , purchase, commision});

        //this.setState({ loader:true });
        let curuser = this.auth.getUser();
        this.$http.get(`admin/wapp/${this.auth.getUser()}/${this.auth.getToken()}/cashier`)
        .then(res => {
            let resdata = this.auth.decryptData(res.data);

            let cashier = resdata.filter(rdata => rdata.username==curuser);

            let cashierBal = (cashier[0] !== undefined) ? cashier[0].cashlimit : 0.00;
            this.setState({ cashierBal });
        }).catch(error => {alert(error.message); this.setState({ loader:false })});
    }

    handleChange = (e) => {
        let unit = e.target.value,
            commision = ((100/80) * unit) - unit,
            purchase = unit

        this.setState({ commision, purchase })
    }

    handleType = (e) => {
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value

        this.setState({ formItems })
    }

    changeStatus = (status) => {
        this.$http.get(`admin/wapp/${this.auth.getUser()}/requestunit/changestatus/${this.state.request.id}/${status}`)
        .then(res => {
            let unitRequests = this.auth.decryptData(res.data);
            
            this.props.handleClick()
        }).catch((error)=>{alert(error)});
    }

    formSubmit = (e) => {
        e.preventDefault();
        
        if(this.state.cashierBal <= 0){

            alert("You don't have enough Unit to sell");

            return;
        }
        
        this.setState({ loader:true, btnDisable: true });

        let formItems = this.state.formItems;
        formItems['usertype'] = this.state.user.usertype;

        if(parseFloat(formItems.totalUnit) > 0){
            this.setState({ loader:false })

            let formData = this.auth.encryptData(JSON.stringify(formItems))
            axios(this.config.PostOptions(formData, 'transaction/wapp/unitransfer'))
            .then((res) => {

                if(isNaN(res.data)){
                    alert(res.data)
                    this.changeStatus(0);
                    $('#btnClose').click();
                }else{
                    this.auth.doAlert(parseInt(res.data))
                }
            })
        }else{
            this.setState({ loader:false })

            alert('Unit amount cannot be zero (0), please check and try again...');
        }
    }

    refreshPage = () =>{
        // let page = (this.state.user.usertype == 2) ? <AgentList /> 
        // : <RetailersList agent={this.state.agent} agents={this.state.agents} />
        // ReactDOM.render(page, document.getElementById('agent_inner'))
    }

    loading = () => <div id="loader"></div>

    render(){
        if(this.state.loader){
            return this.loading();
        }else{
            let { cashierBal, user, formItems, purchase, commision, btnDisable } = this.state;
            return(
                <div className="modal fade" id="unitrequestform" tabIndex="-1" role="dialog" aria-hidden="true">  
                    <div className="modal-dialog">  
                        <div className="modal-content"> 
                            <div className="modal-header">
                                <button id='btnClose' type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                                <h5 className="modal-title">Unit for User: <b>{(this.state.request !== undefined) ? this.state.request.createdby : ''} </b></h5>
                            </div>
                            <div className="modal-body">
                                <form id="frmUnitTransfer" className="smart-form" onSubmit={this.formSubmit}>
                                    <div className="row">
                                        <div className="col-xs-12 col-md-8">
                                            <label className="label col col-5 text-right">Cashier Balance: </label>
                                            <section className="col col-7 ctrltxt"><b>
                                                {numformat.format(parseFloat(cashierBal))}
                                            </b></section>
                                        </div>
                                        <div className="col-xs-12 col-md-8">
                                            <label className="label col col-5 text-right">Type: </label>
                                            <section className="col col-7">
                                                <label className="select">
                                                    <select name="transtype" disabled value={formItems.transtype} onChange={this.handleType} style={{width:'150px'}}>
                                                        <option value='1'>Increase</option>
                                                        <option value='2'>Decrease</option>
                                                    </select> <i></i> 
                                                </label>
                                            </section>
                                        </div>
                                        <div className="col-xs-12 col-md-8">
                                            <label className="label col col-5 text-right">Unit Amount: </label>
                                            <section className="col col-7">
                                                <label className="input">
                                                    <input type="number" min="0" name="purchase" placeholder="Unit Transfer"  
                                                    value={purchase} onChange={this.handleChange} readOnly />
                                                </label>
                                            </section>
                                        </div>
                                        <div className="col-xs-12 col-md-8">
                                            <label className="label col col-5 text-right">Commission: </label>
                                            <section className="col col-7 ctrltxt"><b>{numformat.format(commision)}</b></section>
                                        </div>
                                        <div className="col-xs-12 col-md-8">
                                            <label className="label col col-5 text-right">Total Unit: </label>
                                            <section className="col col-7 ctrltxt"><b>
                                                {numformat.format(parseFloat(commision) + parseFloat(purchase))}
                                            </b></section>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button id="btncancel" type="button" className="btn btn-default" data-dismiss="modal" onClick={() => this.changeStatus(-1)}>Cancel Request</button>
                                <button type="submit" form="frmUnitTransfer" className="btn btn-primary" disabled={btnDisable} >Approve & Send Unit</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}