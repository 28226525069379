import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Config from '../Config';
import Auth from './Auth';
import moment from 'moment';
import UnitRequestForm from './UnitRequestForm';

const curformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2})

export default class TopBar extends Component{
    constructor(){
        super()

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            companySetup: {
                companyName:'', address:'', phone:'', email:'', autologout:5, logo:'', mobileApi:'',
                curuser:this.auth.getUser(), token:this.auth.getToken()
            },
            unitRequests: [], lastUpdated: new Date(),
            unitRequest:''
        }
    }

    componentDidMount(){
        this.getCompanySetup();

        this.handleClick();
    }

    getCompanySetup = () => {
        this.$http.get('admin/wapp/setup')
        .then(res => {
            let companySetup = this.auth.decryptData(res.data)
            
            this.setState({ companySetup })
        }).catch((error)=>{alert(error)})
    }

    handleClick = () => {
        this.$http.get(`admin/wapp/${this.auth.getUser()}/requestunit/status/1`)
        .then(res => {
            let unitRequests = this.auth.decryptData(res.data);
            
            this.setState({ unitRequests, lastUpdated:new Date() })
        }).catch((error)=>{alert(error)})
    }

    showRequestForm = (request) => this.setState({unitRequest:request});

    doLogout = () => {
        this.auth.logout();
    }

    render(){
        return(
            <>
                <UnitRequestForm unitRequest={this.state.unitRequest} handleClick={this.handleClick} />

                <header id="header">
                    <div id="logo-group">
                        <span id="logo" className="text-primary"> 
                            <img src={this.config.ImagePath('custfiles') + '/' + this.state.companySetup.logo} alt={this.state.companySetup.companyName} style={{height:'23px',width: '23px'}} /> &nbsp; 
                            <b>{this.state.companySetup.companyName}</b>
                        </span>
                        
                        <span id="activity" className="activity-dropdown"> <i className="fa fa-user"></i> <b className="badge"> {this.state.unitRequests.length} </b> </span>
                        <div className="ajax-dropdown">
                            <div className="btn-group btn-group-justified" data-toggle="buttons">
                                <label className="btn btn-default">
                                    <input type="radio" name="activity" id={`requests`} 
                                    />
                                    Requests ({this.state.unitRequests.length}) </label>
                                {/* <label className="btn btn-default">
                                    <input type="radio" name="activity" id="ajax/notify/notifications.html" />
                                    - (0) </label>
                                <label className="btn btn-default">
                                    <input type="radio" name="activity" id="ajax/notify/tasks.html" />
                                    - (0) </label> */}
                            </div>

                            <div className="ajax-notifications custom-scroll">

                                {this.state.unitRequests.map(urequest => 
                                <div className='' data-toggle="modal" data-target="#unitrequestform"
                                    style={{padding: '10px 15px', borderBottom: '1px solid gray'}} title='Approve'
                                    role='button' onClick={() => this.showRequestForm(urequest)}>
                                        <div>Date: <b>{moment(urequest.createdon).format('DD MMM, YYYY hh:mm A')}</b></div>
                                        <div>Tran #: <b>{urequest.transId}</b></div>
                                        <div>User: <b>{urequest.createdby}</b></div>
                                        <div>Amount(GHS): <b>{curformat.format(urequest.amount)}</b></div>
                                </div>)}
                                {/* <div className="alert alert-transparent">
                                    <h4>Click a button to show messages here</h4>
                                    This blank page message helps protect your privacy, or you can show the first message here automatically.
                                </div>

                                <i className="fa fa-lock fa-4x fa-border"></i> */}
                                
                            </div>
                            
                            <span> Last updated on: {moment(this.state.lastUpdated).format('DD/MM/YYYY hh:mm A')}
                                <button type="button" data-loading-text="<i className='fa fa-refresh fa-spin'></i> Loading..." 
                                    className="btn btn-xs btn-default pull-right" onClick={this.handleClick}>
                                    <i className="fa fa-refresh"></i>
                                </button> 
                            </span>

                        </div>
                    </div>

                    
                
                    <div className="pull-right">
                        <div id="hide-menu" className="btn-header pull-right">
                            <span> <a href="javascript:void(0);" data-action="toggleMenu" title="Collapse Menu"><i className="fa fa-reorder"></i></a> </span>
                        </div>
                        
                        <ul id="mobile-profile-img" className="header-dropdown-list hidden-xs padding-5">
                            <li className="">
                                <a href="#" className="dropdown-toggle no-margin userdropdown" data-toggle="dropdown"> 
                                    <img src="img/avatars/sunny.png" alt="John Doe" className="online" />  
                                </a>
                                <ul className="dropdown-menu pull-right">
                                    <li>
                                        <a href="javascript:void(0);" className="padding-10 padding-top-0 padding-bottom-0"><i className="fa fa-cog"></i> Setting</a>
                                    </li>
                                    <li className="divider"></li>
                                    <li>
                                        <a href="index.html#ajax/profile.html" className="padding-10 padding-top-0 padding-bottom-0"> <i className="fa fa-user"></i> <u>P</u>rofile</a>
                                    </li>
                                    <li className="divider"></li>
                                    <li>
                                        <a href="javascript:void(0);" className="padding-10 padding-top-0 padding-bottom-0" data-action="toggleShortcut"><i className="fa fa-arrow-down"></i> <u>S</u>hortcut</a>
                                    </li>
                                    <li className="divider"></li>
                                    <li>
                                        <a href="javascript:void(0);" className="padding-10 padding-top-0 padding-bottom-0" data-action="launchFullscreen"><i className="fa fa-arrows-alt"></i> Full <u>S</u>creen</a>
                                    </li>
                                    <li className="divider"></li>
                                    <li>
                                        <a href="login.html" className="padding-10 padding-top-5 padding-bottom-5" data-action="userLogout"><i className="fa fa-sign-out fa-lg"></i> <strong><u>L</u>ogout</strong></a>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <div id="logout" className="btn-header transparent pull-right">
                            <span> <Link to="#" onClick={this.doLogout} title="Sign Out" data-action="userLogout" data-logout-msg="Are you sure, you want to logout?">
                                <i className="fa fa-sign-out"></i>
                            </Link></span>
                        </div>

                        <div id="search-mobile" className="btn-header transparent pull-right">
                            <span> <a href="javascript:void(0)" title="Search"><i className="fa fa-search"></i></a> </span>
                        </div>
                        
                        <form action="#" className="header-search pull-right">
                            <input id="search-fld" type="text" name="param" placeholder="Find reports and more" />
                            <button type="submit">
                                <i className="fa fa-search"></i>
                            </button>
                            <a href="javascript:void(0);" id="cancel-search-js" title="Cancel Search"><i className="fa fa-times"></i></a>
                        </form>

                        <div id="fullscreen" className="btn-header transparent pull-right">
                            <span> <a href="javascript:void(0);" data-action="launchFullscreen" title="Full Screen"><i className="fa fa-arrows-alt"></i></a> </span>
                        </div>

                        <div id="speech-btn" className="btn-header transparent pull-right hidden-sm hidden-xs">
                            <div> 
                                <a href="javascript:void(0)" title="Voice Command" data-action="voiceCommand"><i className="fa fa-microphone"></i></a> 
                                <div className="popover bottom"><div className="arrow"></div>
                                    <div className="popover-content">
                                        <h4 className="vc-title">Voice command activated <br /><small>Please speak clearly into the mic</small></h4>
                                        <h4 className="vc-title-error text-center">
                                            <i className="fa fa-microphone-slash"></i> Voice command failed
                                            <br /><small className="txt-color-red">Must <strong>"Allow"</strong> Microphone</small>
                                            <br /><small className="txt-color-red">Must have <strong>Internet Connection</strong></small>
                                        </h4>
                                        <a href="#" className="btn btn-success" onClick="commands.help()">See Commands</a> 
                                        <a href="#" className="btn bg-color-purple txt-color-white" onClick="$('#speech-btn .popover').fadeOut(50);">Close Popup</a> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ul className="header-dropdown-list hidden-xs">
                            <li>
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown"> <img src="assets/img/blank.gif" className="flag flag-us" alt="United States" /> <span> US</span> <i className="fa fa-angle-down"></i> </a>
                                <ul className="dropdown-menu pull-right">
                                    <li className="active">
                                        <a href="javascript:void(0);"><img src="assets/img/blank.gif" className="flag flag-us" alt="United States" /> English (US)</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);"><img src="assets/img/blank.gif" className="flag flag-fr" alt="France" /> Français</a>
                                    </li>
                                    
                                </ul>
                            </li>
                        </ul>
                    </div>
                </header>
            </>
        )
    }
}