import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import $ from 'jquery'
import ReactDOM from 'react-dom'
import axios from 'axios'

export default class MobilMoneyConfig extends Component{
    constructor(props){
        super(props)
        
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems:{
                companyName:'', slogan:'', motto:'', address:'', phone:'',
                 email:'', autologout:5, commission:0,
                logo:'', poslogo:'', mobileApi:0, mobilePayApi:0, smsApi:0, 
                emailApi:0, website:'', curuser:this.auth.getUser(), 
                token:this.auth.getToken()
            },
            docItems: {logo: null, poslogo: null},
            mobileApis:[], smsApis:[], emailApis:[]
        }
    }

    componentDidMount(){
        this.getDropdowns()
    }

    componentDidUpdate(nextProps){
        if(nextProps !== this.props){
            this.getDropdowns()
        }
    }

    getReportData = (rdata) =>{
        this.$http.get('admin/wapp/setup')
        .then(res => {
            let formItems = this.auth.decryptData(res.data)
            formItems['curuser'] = this.auth.getUser()
            formItems['token'] = this.auth.getToken()

            if(formItems.emailApi == 0){
                formItems['emailApi'] = rdata.emailApi
            }
            if(formItems.mobileApi == 0){
                formItems['mobileApi'] = rdata.mobileApi
            }
            if(formItems.smsApi == 0){
                formItems['smsApi'] = rdata.smsApi
            }
            if(formItems.mobilePayApi == 0){
                formItems['mobilePayApi'] = rdata.mobilePayApi
            }
            
            this.setState({ formItems })
        }).catch((error)=>{alert(error)})
    }

    getDropdowns = () =>{
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/api')
        .then(res => {
            let resdata = this.auth.decryptData(res.data)
            let formItems = this.state.formItems

            let smsApis = resdata.filter(rdata => rdata.apiType == 1)
            let mobileApis = resdata.filter(rdata => rdata.apiType == 2)
            let emailApis = resdata.filter(rdata => rdata.apiType == 3)

            if(formItems.emailApi == 0 && emailApis[0] !== undefined){
                formItems['emailApi'] = emailApis[0].id
            }
            if(formItems.mobileApi == 0 && mobileApis[0] !== undefined){
                formItems['mobileApi'] = mobileApis[0].id
            }
            if(formItems.mobilePayApi == 0 && mobileApis[0] !== undefined){
                formItems['mobilePayApi'] = mobileApis[0].id
            }
            if(formItems.smsApi == 0 && smsApis[0] !== undefined){
                formItems['smsApi'] = smsApis[0].id
            }

            //alert(JSON.stringify(formItems))
            this.setState({ mobileApis, smsApis, emailApis, formItems })

            this.getReportData(formItems)
        }).catch((error)=>{console.log(error)})
    }

    imageClick = (id) => {
        $('#' + id).click()
    }

    imageChange = (e, picon) =>{
        let img = e.target.files[0]
        let ext = img.name.split('.')
        ext = ext[ext.length-1].toLowerCase()

        if(ext === 'jpg' || ext === 'jpeg' || ext === 'png'){
            let docItems = this.state.docItems
            let formItems = this.state.formItems
            docItems[e.target.name] = img
            formItems[e.target.name] = img.name
            
            this.setState({ docItems, formItems })

            let imgurl =  URL.createObjectURL(img)
            ReactDOM.render(
                <img src={imgurl} style={{width:'100px'}} />,
                document.getElementById(picon)
            )
        }else{
            alert('The supported files are: jpg and png...')
        }
    }

    handleChange = (e) => { 
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value
        this.setState({ formItems }) 
    }

    formSubmit=(e)=>{
        e.preventDefault()

        let formItems = this.state.formItems

        alert(JSON.stringify(formItems))
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'admin/wapp/setup'))
        .then((res) => {
            if(isNaN(res.data)){
                this.saveImage(formItems)
                alert(this.auth.decryptText(res.data))
                this.componentDidMount()
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error)=>{alert(error)})
    }

    saveImage = (formItems) =>{
        let docItems = this.state.docItems
        if(docItems.logo !== null){
            axios(this.config.PostMulti(docItems.logo, 'admin/wapp/agentfile/' + formItems.logo))
            .then((res) => {
                
            }).catch((error)=>{alert(error)})
        }

        if(docItems.poslogo !== null){
            axios(this.config.PostMulti(docItems.poslogo, 'admin/wapp/agentfile/' + formItems.poslogo))
            .then((res) => {
                
            }).catch((error)=>{alert(error)})
        }
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12" style={{padding:'10px 20px 0 20px'}}>
                    <h3 className="pull-left">Software Setup</h3>
                    <div className="btn-group btn-group-sm pull-right clearfix">
                        <button type="submit" className="btn btn-success" form="frmSetup">
                            <i className="glyphicon glyphicon-save"></i> Update
                        </button>
                    </div>
                </article>
                <article className="col-xs-12"><hr className="hrsp" /></article>
                <article className="col-xs-12 col-sm-10">
                    <form id="frmSetup" className="smart-form" onSubmit={this.formSubmit}>
                        <div className="row">
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Company Name: </label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="companyName" placeholder="Company Name" required 
                                        value={this.state.formItems.companyName} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Address: </label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="address" placeholder="Address" required 
                                        value={this.state.formItems.address} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Slogan: </label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="slogan" placeholder="Slogan" required 
                                        value={this.state.formItems.slogan} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Motto: </label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="motto" placeholder="Motto" required 
                                        value={this.state.formItems.motto} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Phones: </label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="tel" name="phone" placeholder="Phone" required 
                                        value={this.state.formItems.phone} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Email: </label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="email" min="Email Address" required 
                                        value={this.state.formItems.email} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Website: </label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="website" min="Website Address" required 
                                        value={this.state.formItems.website} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Auto Logout (min): </label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="number" name="autologout" min="1" required 
                                        value={this.state.formItems.autologout} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Momo Load Cash API: </label>
                                <section className="col col-8">
                                    <label className="select">
                                        <select name="mobileApi" value={this.state.formItems.mobileApi} onChange={this.handleChange}>
                                            {this.state.mobileApis.map(api => (
                                                <option value={api.id}>{api.name}</option>
                                            ))}
                                        </select> <i></i> 
                                    </label>
                                </section>
                            </div>

                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Momo Cashout API: </label>
                                <section className="col col-8">
                                    <label className="select">
                                        <select name="mobilePayApi" value={this.state.formItems.mobilePayApi} onChange={this.handleChange}>
                                            {this.state.mobileApis.map(api => (
                                                <option value={api.id}>{api.name}</option>
                                            ))}
                                        </select> <i></i> 
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Email: </label>
                                <section className="col col-8">
                                    <label className="select">
                                        <select name="emailApi" value={this.state.formItems.emailApi} onChange={this.handleChange}>
                                            {this.state.emailApis.map(api => (
                                                <option value={api.id}>{api.name}</option>
                                            ))}
                                        </select> <i></i> 
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">SMS API: </label>
                                <section className="col col-8">
                                    <label className="select">
                                        <select name="smsApi" value={this.state.formItems.smsApi} onChange={this.handleChange}>
                                            {this.state.smsApis.map(api => (
                                                <option value={api.id}>{api.name}</option>
                                            ))}
                                        </select> <i></i> 
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Sales Commission: </label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="number" name="commission" min="0" step={'any'} required 
                                        value={this.state.formItems.commission} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>
                        </div>
                    </form>
                </article>
                <article className="col-xs-12 col-sm-2">
                    <div id="custImgCon" className="table-bordered imgCon text-center unselectable" onClick={()=>this.imageClick('fileLogo')}>
                        <img src={this.config.ImagePath('custfiles') + '/' + this.state.formItems.logo} style={{width:'100'}} alt="Logo" />
                    </div>
                    <input type="file" name="logo" style={{display:'none'}} id="fileLogo" onChange={(e)=>this.imageChange(e,'custImgCon')} />
                    <hr className="hrsp" />
                    <div id="posImgCon" className="table-bordered imgCon text-center unselectable" onClick={()=>this.imageClick('posLogo')}>
                        <img src={this.config.ImagePath('custfiles') + '/' + this.state.formItems.poslogo} style={{width:'100'}} alt="POS" />
                    </div>
                    <input type="file" name="poslogo" style={{display:'none'}} id="posLogo" onChange={(e)=>this.imageChange(e,'posImgCon')} />
                </article>
            </div>
        )
    }
}